import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

import Box from '@sholdi/primitives/atoms/Box'
import Text from '@sholdi/primitives/atoms/Box'
import TimesNot from '@sholdi/icons/TimesNot'
import CircleIcon from './CircleIcon'

import Actions from './Actions'
import ProgressBar from './ProgressBar'

import { ToastSubtitle, ToastTitle } from './components'

const IconToast = forwardRef((props, ref) => {
  const {
    inverse,
    title,
    iconComponent: IconComponent,
    subtitle,
    timestamp,
    isIcon,
    color,
    progress,
    progressDesc,
    actions,
    onClose,
    children,
    variant = 'iconToastDefault',
    ...rest
  } = props

  return (
    <div ref={ref} className="w-full flex rounded-lg bg-white" {...rest}>
      <TimesNot
        onClick={onClose}
        className="absolute top-1 right-1 mr-2 text-gray-500 cursor-pointer"
      />
      <div
        className={twMerge(
          'w-full flex rounded-lg  gap-3 text-black pr-4',
          inverse ? 'flex-col' : 'flex-row',
        )}
      >
        {IconComponent && (
          <CircleIcon
            icon={IconComponent}
            color={color}
            iconProps={{ className: 'size-5' }}
            innerCircleClassName={isIcon ? 'size-8' : 'w-fit'}
            className={isIcon ? 'size-12' : 'w-fit'}
          />
        )}
        {!children && (
          <Box className={twMerge('flex justify-center flex-col mr-9')}>
            <Box className="flex h-fit">
              <ToastTitle variant={variant} className="text-black h-fit">
                {title}
              </ToastTitle>
              {timestamp && (
                <ToastSubtitle variant={variant} className="text-black ml-2">
                  {timestamp}
                </ToastSubtitle>
              )}
            </Box>
            {subtitle && (
              <Text variant={variant} className="text-black mt-1">
                {subtitle}
              </Text>
            )}
            {progress && (
              <ProgressBar
                progress={progress}
                progressDesc={progressDesc}
                color={color}
              />
            )}
            {actions && <Actions actions={actions} />}
          </Box>
        )}
        {children && children}
      </div>
    </div>
  )
})

IconToast.displayName = 'IconToast'

IconToast.propTypes = {
  inverse: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func,
  iconComponent: PropTypes.elementType,
  timestamp: PropTypes.string,
  isIcon: PropTypes.bool,
  color: PropTypes.string,
  progress: PropTypes.string,
  progressDesc: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.elementType,
}

export default IconToast
