/* eslint-disable react/prop-types */
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

import Text from '@sholdi/primitives/atoms/Text'

import getVariantChildStyles from '@sholdi/shared/helpers/getVariantChildStyles'

const variants = {
  iconToastDefault: {
    title: 'font-heading font-medium text-sm leading-5',
    subtitle: 'font-body text-sm leading-5 text-black',
  },
  iconToastHighlighted: {
    title: 'font-body font-bold text-xl leading-7',
    subtitle: 'font-body text-sm leading-5 text-black',
  },
}

const title = cva('', {
  variants: {
    variant: getVariantChildStyles(variants, 'title'),
  },
})

export const ToastTitle = ({ variant, children, className, ...props }) => (
  <Text className={twMerge(title({ variant }), className)} {...props}>
    {children}
  </Text>
)

const subtitle = cva('', {
  variants: {
    variant: getVariantChildStyles(variants, 'subtitle'),
  },
})

export const ToastSubtitle = ({ variant, children, className, ...props }) => (
  <Text className={twMerge(subtitle({ variant }), className)} {...props}>
    {children}
  </Text>
)
