import PropTypes from 'prop-types'

import Text from '@sholdi/primitives/atoms/Box'

const colors = {
  primary: {
    lightest: 'bg-primary-lightest',
    main: 'bg-primary-main',
  },
  secondary: {
    lightest: 'bg-secondary-lightest',
    main: 'bg-secondary-main',
  },
  success: {
    lightest: 'bg-success-lightest',
    main: 'bg-success-main',
  },
  error: {
    lightest: 'bg-error-lightest',
    main: 'bg-error-main',
  },
  warning: {
    lightest: 'bg-warning-lightest',
    main: 'bg-warning-main',
  },
}

const ProgressBar = ({ progress, progressDesc, color = 'primary' }) => (
  <div className="flex w-full flex-col items-end mt-4">
    <div className={'w-full h-2 rounded-sm' + ' ' + colors[color].lightest}>
      <div
        className={'h-2 rounded-sm' + ' ' + colors[color].main}
        style={{ width: progress }}
      />
    </div>
    <Text className="font-heading font-medium text-sm leading-5 text-gray-700 mt-2">
      {progressDesc}
    </Text>
  </div>
)

ProgressBar.displayName = 'ProgressBar'

ProgressBar.propTypes = {
  progress: PropTypes.string,
  progressDesc: PropTypes.string,
  color: PropTypes.string,
}

export default ProgressBar
